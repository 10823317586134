@import "@/src/styles/common.scss";
.video-loading.toast-container {
    // background-color: transparent;
}
.dialog-paper {
    .frame-container {
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}
